<script>
import { vatAnnexPreview } from '@/views/invoiceManagement/VATinvoice/service'
import { changePaymentStatusTemplate } from '@/@core/utils/options'
import ProgressLoading from '@/components/ProgressLoading/index.vue'
import BatchImportDialog from '@/views/dataCenter/ytAccountForm/components/batchImportDialog.vue'
import Tooltip from '@/components/tooltip/index.vue'
import XwPopover from '@/components/XwPopover.vue'
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import { MessageBox as MessageBoxElement } from 'element-ui'
import { formatNumber, processNumbers, addSpace, getSearchFormFields } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, nextTick } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { signStatusColorMap, checkStatusColorMap, paymentStatusColorMap, remittanceStatusColorMap } from '@/@core/utils/colorMap'
import Details from '@/views/statementManagement/components/StatementDetail/index.vue'
import { downloadFile } from '@/@core/utils/form'
import ImportFailDialog from '@/views/dataCenter/ytAccountForm/components/importFailDialog.vue'
import NullifyDialog from '../../components/nullify/index.vue'
import BatchChangeRemitDialog from '../../components/batch-change-remit-status/index.vue'
import mapEnum, { filterMap, textMap } from '../mapEnum'
import { getProformaPage, pageStatistics, proformaExport, proformaRegenerate, sendSms, sendSmsCount, proformaByImport, importFailExport } from '../service'

export default {
  name: 'CommonTable',
  components: { SuperTable, Pagination, AppView, SearchForm, Details, BatchChangeRemitDialog, XwStatusIcon, XwPopover, Tooltip, BatchImportDialog, ProgressLoading, ImportFailDialog, NullifyDialog },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '全部',
    },
    activeName: {
      type: String,
      default: '全部',
    },
  },
  setup(props, { emit }) {
    const table = ref()
    const selected = ref([])
    const formRef = ref()
    const { message, loading: Loading, MessageBox } = useMessage()
    const searchForm = computed(() => props.formData).value
    const loading = ref(false)

    const searchConfig = computed(() => [
      {
        el: 'VTextField', props: 'number', label: '编号',
      },
      {
        el: 'VTextField', props: 'cpName', label: 'CP名称',
      },
      {
        el: 'VSelect', props: 'sendSmsStatus', label: '短信状态', items: mapEnum.sendSmsStatusOptions, itemValue: 'value', itemText: 'label', auth: props.label !== '待发起',
      },
      {
        el: 'DateMonthRange', props: 'payoutPeriod', label: 'Payout Period',
      },
      {
        el: 'DatePickers', props: 'times', label: '创建时间', clearable: true, range: true,
      },
      {
        el: 'DateTimeRange', props: 'signTime', label: '签字日期', clearable: false, range: true, auth: props.label !== '已签字', span: 2,
      },
      {
        el: 'VSelect', props: 'paymentStatus', label: '汇款状态', items: mapEnum.paymentStatusOptions, itemValue: 'value', itemText: 'label', auth: props.label !== '已签字',
      },
    ])
    const newSearchConfig = computed(() => searchConfig.value.filter(item => !item.auth))

    const searchMap = computed(() => ({
      signTime: {
        signAtStart: searchForm.signTime[0],
        signAtEnd: searchForm.signTime[1],
      },
      payoutPeriod: {
        payoutPeriodStart: searchForm.payoutPeriod[0],
        payoutPeriodEnd: searchForm.payoutPeriod[1],
      },
      times: {
        createdAtStart: searchForm.times[0],
        createdAtEnd: searchForm.times[1],
      },
    }))

    const newSearchForm = computed(() => ({
      ...getSearchFormFields(newSearchConfig.value, searchForm, searchMap.value),
    }))

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const statisticsForm = ref({})
    const upPercentage = ref(0)
    const failList = ref([])
    const fileKey = ref('')
    const visibleDetails = ref(false)
    const visibleBatchChangeRemit = ref(false)
    const visibleImportChange = ref(false)
    const visibleLoading = ref(false)
    const visibleImportFail = ref(false)
    const visibleNullify = ref(false)
    const tableData = ref([])
    const activeRow = ref({})
    const init = () => {
      loading.value = true
      getProformaPage(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
        emit('updateTotal', newSearchForm.value)
      }).catch(error => {
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      }).finally(() => {
        loading.value = false
      })
    }

    const getStatisticsForm = () => {
      pageStatistics(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const created = () => {
      init()
      getStatisticsForm()
    }

    const refresh = () => {
      table.value.clear()
      created()
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      proformaExport(props.tab, { ...newSearchForm.value }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的形式发票${props.label}报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    // 打开详情弹窗
    const detailForm = ref({})
    const openDetail = (settlementNo, platform) => {
      visibleDetails.value = true
      detailForm.value.settlementNo = settlementNo
      detailForm.value.platform = platform
    }

    // 查看
    const review = filePath => {
      if (!filePath) return message.info('PDF生成中，暂不支持查看')
      if (filePath.includes('http')) {
        window.open(filePath)
      } else {
        vatAnnexPreview({ filePath }).then(({ data }) => {
          window.open(data.data)
        }).catch(error => error)
      }
    }

    // 作废
    const nullify = row => {
      activeRow.value = row
      visibleNullify.value = true
    }

    // 重新发起
    const regenerate = id => {
      const loadingRef = Loading()
      proformaRegenerate(id).then(({ data }) => {
        loadingRef.close()
        if (data.status === 200) message.success('操作成功')
        created()
      }).catch(error => {
        loadingRef.close()
        message.error(error.response.data.message)
      })
    }

    // 批量变更汇款状态
    const batchRemitForm = ref({})

    const batchChangeStatus = () => {
      batchRemitForm.value = {
        ids: selected.value.map(item => item.id),
        ...newSearchForm.value,
      }
      visibleBatchChangeRemit.value = true
    }

    // 发送短信
    const sendInfo = (total, not, has) => {
      MessageBoxElement.alert(`
      <p>已选择<span  style="color:#9155FD"> ${total || 0} </span> 条，其中<span style="color:#9155FD"> ${not || 0} </span>条未发送，<span  style="color:#9155FD"> ${has || 0} </span>条已发送或已发送未回执，是否确认发送短信通知？</p>`, '批量发送短信', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        cancelButtonText: addSpace('取消'),
        confirmButtonText: addSpace('确定'),
      }).then(() => {
        sendSms(props.tab, { ...newSearchForm.value, ids: selected.value.map(item => item.id) }).then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          refresh()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }
    // 批量发送短信
    const batchSendSms = () => {
      if (!selected.value.length) {
        sendSmsCount(props.tab, newSearchForm.value).then(({ data }) => {
          sendInfo(data.data.totalSmsCount, data.data.failedSmsCount, data.data.successSmsCount)
        }).catch(error => message.error(error.response.data.message))
      } else {
        sendInfo(selected.value.length, selected.value.filter(item => [0, 3].includes(item.sendSmsStatus)).length, selected.value.filter(item => [1, 2].includes(item.sendSmsStatus)).length)
      }
    }

    // 导入修改
    const batchSubmit = key => {
      fileKey.value = key
      MessageBox({
        message: '导入后，对应结算单和所关联的境内形式发票的汇款状态，会根据关联的结算单是否全部已汇款对应变更，是否继续？',
        type: 'warning',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        visibleLoading.value = true
        proformaByImport(encodeURIComponent(key), progressEvent => {
          upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        }).then(() => {
          message.success('导入成功')
          visibleImportChange.value = false
          init()
        }).catch(error => {
          const { data } = error.response
          if (data.data.length) {
            failList.value = data.data
            visibleImportFail.value = true
          }
          message.error(error.response.data.message)
        }).finally(() => { visibleLoading.value = false })
      }).catch(error => error)
    }

    // 导出导入错误信息文件
    const downLoadErrorFile = () => {
      importFailExport('finance/2024-11-13//1731482602276_批量修改汇款状态模板.xlsx').then(data => {
        console.log(data)
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的失败原因文件`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => message.error(error.response.data.message))
    }
    // 下载模板
    const clickDownTemplate = () => {
      window.open(changePaymentStatusTemplate)
    }

    // 关闭失败原因弹窗
    const closeBatch = () => {
      visibleImportChange.value = false
      visibleImportFail.value = false
      created()
    }

    const getSettlementNoList = arr => arr.flatMap(({ platform, settlementNoList }) => settlementNoList.map(settlementNo => ({ platform, settlementNo })))

    const selectionChange = data => {
      selected.value = data
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.payoutPeriod = []
        searchForm.signTime = []
        created()
      }).catch(error => error)
    }

    const search = () => {
      page.page = 1
      init()
      getStatisticsForm()
    }

    onMounted(() => {
      if (props.activeName === props.label) {
        created()
      }
    })

    return {
      newSearchConfig,
      formRef,
      page,
      tableData,
      reset,
      search,
      init,
      created,
      refresh,
      handleCurrentChange,
      handleSizeChange,
      statisticsForm,
      filterMap,
      searchForm,
      selectionChange,
      table,

      review,
      selected,
      signStatusColorMap,
      checkStatusColorMap,
      paymentStatusColorMap,
      remittanceStatusColorMap,
      openDetail,
      detailForm,
      visibleDetails,
      getSettlementNoList,
      loading,
      nullify,
      regenerate,
      textMap,
      processNumbers,
      addSpace,
      exportExcel,
      visibleBatchChangeRemit,
      batchChangeStatus,
      batchRemitForm,
      batchSendSms,
      visibleImportChange,
      batchSubmit,
      clickDownTemplate,
      upPercentage,
      visibleLoading,
      visibleImportFail,
      failList,
      downLoadErrorFile,
      closeBatch,
      visibleNullify,
      activeRow,

    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <SearchForm
          ref="formRef"
          :columns="newSearchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex align-center">
          <v-btn v-if="label === '待发起'" v-auth="['proformaInvoice-sendSms']" class="app-link" color="primary" @click="batchSendSms">
            批量发送短信
          </v-btn>

          <v-menu v-if="label === '已签字'" offset-y open-on-hover>
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                class="app-link"
                v-on="on"
              >
                {{ addSpace('变更汇款状态') }} <i class="iconfont icon-a-xialakuang2x fs12 ml5 pt2"></i>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-auth="['proformaInvoice-changestatus']" class="fs14" @click="batchChangeStatus">
                按勾选修改
              </v-list-item>
              <v-list-item v-auth="['proformaInvoice-batch-changestatus-import']" class="fs14" @click="visibleImportChange = true">
                按导入修改
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-auth="['proformaInvoice-export']" class="app-link" :color="['已签字','待发起'].includes(label) ? '' : 'primary'" :outlined="['已签字','待发起'].includes(label) ? true : false" @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
          <div class="error--text fs14 app-link">
            {{ textMap[tab] }}
          </div>
          <div v-if="tab === 2 && selected.length" class="fs14">
            已选择 <span class="app-link">{{ selected.length }}</span> 条
          </div>
        </div>
        <div class="num">
          <span> 审核通过金额: <span class="amount"> {{ processNumbers(statisticsForm.revenue, '￥' ) }} </span></span>
        </div>
      </div>
    </template>

    <SuperTable
      ref="table"
      :columns="header"
      :data="tableData"
      :loading="loading"
      :checkbox-config="{reserve:true}"
      :row-config="{keyField:'id'}"
      @selection-change="selectionChange"
    >
      <template #payoutPeriod="{row}">
        <div class="domesticLinks">
          {{ row.payoutPeriod }}
        </div>
      </template>
      <template #failReason="{row}">
        <div class="domesticLinks">
          {{ row.failReason }}
        </div>
      </template>
      <template #settlementList="{row}">
        <xw-popover :list="row.settlementList" @openDetail="openDetail"></xw-popover>
      </template>
      <template #signStatus="{row}">
        <xw-status-icon :color="signStatusColorMap[row.signStatus]" :text="filterMap('signStatusOptions',row.signStatus)" />
      </template>
      <template #paymentStatus="{row}">
        <xw-status-icon :color="paymentStatusColorMap[row.paymentStatus]" :text="row.paymentStatus" />
      </template>
      <template #sendSmsStatus="{row}">
        <div class="flex-left gap5">
          <xw-status-icon :color="remittanceStatusColorMap[row.sendSmsStatus]" :text="filterMap('sendSmsStatusOptions',row.sendSmsStatus)" />
          <Tooltip v-if="row.sendSmsError" bottom>
            <div>{{ row.sendSmsError }}</div>
          </Tooltip>
        </div>
        <div>
          {{ row.sendSmsTime }}
        </div>
      </template>
      <template #updatedAt="{row}">
        <div v-if="label === '已签字'">
          {{ row.paymentStatus !== '待汇款' ? row.updatedAt : '-' }}
        </div>
        <div v-else>
          {{ row.updatedAt || '-' }}
        </div>
      </template>
      <template #actions="{row}">
        <span v-if="!['待生成'].includes(label)" v-auth="['proformaInvoice-view']" class="app-link" @click="review(row.pdfUrl)">查看</span>
        <span v-if="!['已签字'].includes(label)" v-auth="['proformaInvoice-cancel']" class="app-link" @click="nullify(row)">作废</span>
        <span v-if="['发起失败'].includes(label)" v-auth="['proformaInvoice-relaunch']" class="app-link" @click="regenerate(row.id)">重新发起</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Details v-if="visibleDetails" :form="detailForm" @close="visibleDetails = false" />

    <BatchChangeRemitDialog v-if="visibleBatchChangeRemit" :form="batchRemitForm" platform="proforma" :tab="tab" @close="visibleBatchChangeRemit = false" @refresh="refresh" />

    <BatchImportDialog v-if="visibleImportChange" title="变更汇款状态-按导入修改" @close="visibleImportChange = false" @submit="batchSubmit">
      <div class="template">
        <div class="font-weight-black black--text">
          使用说明
        </div>
        <div>
          1、下载 <span class="download-link" @click="clickDownTemplate">《结算单变更汇款状态模板》</span>填写结算单编号；
        </div>
        <div>2、上传文件，变更结算单汇款状态，所关联的境内形式发票的汇款状态，会根据关联的结算单是否全部已汇款对应变更</div>
      </div>
    </BatchImportDialog>

    <ProgressLoading v-if="visibleLoading" :progress="upPercentage" @close="visibleLoading = false" />

    <ImportFailDialog v-if="visibleImportFail" :data="failList" :show-reject-btn-text="false" @close="visibleImportFail = false" @closeBatch="closeBatch" @downLoadErrorFile="downLoadErrorFile">
    </ImportFailDialog>

    <NullifyDialog v-if="visibleNullify" :active-row="activeRow" invoice-type="proforma" @close="visibleNullify = false" @refresh="created" />
  </AppView>
</template>
  <style scoped lang='scss'>
  .num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 10px;
  .amount{
      color: #FFA80F;
      padding: 0 10px;
  }
  }
.template{
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  div{
    line-height: 30px;
  }
}

</style>
