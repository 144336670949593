<script>
import { vatAnnexPreview } from '@/views/invoiceManagement/VATinvoice/service'
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import XwPopover from '@/components/XwPopover.vue'
import { isImgFormat } from '@/@core/utils/is'
import ButtonList from '@/components/ButtonList/index.vue'
import { formatNumber, processNumbers, addSpace, getSearchFormFields } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, nextTick } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { remittanceStatusColorMap, paymentStatusColorMap } from '@/@core/utils/colorMap'
import Details from '@/views/statementManagement/components/StatementDetail/index.vue'
import { downloadFile } from '@/@core/utils/form'
import NullifyDialog from '../../components/nullify/index.vue'
import { getOutseaProformaPage, pageStatistics, getPdfUrl, outseaProformaExport } from '../service'
import ConfirmDialog from './ConfirmDialog.vue'
import mapEnum, { filterMap, textMap } from '../mapEnum'
import BatchChangeRemitDialog from '../../components/batch-change-remit-status/index.vue'

export default {
  name: 'CommonTable',
  components: { SuperTable, Pagination, AppView, SearchForm, Details, ButtonList, ConfirmDialog, BatchChangeRemitDialog, XwPopover, XwStatusIcon, NullifyDialog },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '全部',
    },
    activeName: {
      type: String,
      default: '全部',
    },
  },
  setup(props, { emit }) {
    const table = ref()
    const selected = ref([])
    const formRef = ref()
    const { message, loading: load, previewImage } = useMessage()
    const searchForm = computed(() => props.formData).value
    const loading = ref(false)

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const searchConfig = computed(() => [
      {
        el: 'VTextField', props: 'number', label: '编号',
      },
      {
        el: 'VTextField', props: 'cpName', label: 'CP名称',
      },
      {
        el: 'DateMonthRange', props: 'payoutPeriod', label: 'Payout Period',
      },
      {
        el: 'DatePickers', props: 'times', label: '创建时间', clearable: true, range: true,
      },
      {
        el: 'VSelect', props: 'paymentStatus', label: '汇款状态', items: mapEnum.paymentStatusOptions, itemValue: 'value', itemText: 'label', auth: props.label !== '已确认',
      },
    ])

    const newSearchConfig = computed(() => searchConfig.value.filter(item => !item.auth))

    const searchMap = computed(() => ({
      payoutPeriod: {
        payoutPeriodStart: searchForm.payoutPeriod[0],
        payoutPeriodEnd: searchForm.payoutPeriod[1],
      },
      times: {
        createdAtStart: searchForm.times[0],
        createdAtEnd: searchForm.times[1],
      },
    }))

    const newSearchForm = computed(() => ({
      ...getSearchFormFields(newSearchConfig.value, searchForm, searchMap.value),
    }))

    const statisticsForm = ref({})
    const activeRow = ref({})
    const visibleDetails = ref(false)
    const visibleConfirm = ref(false)
    const visibleBatchChangeRemit = ref(false)
    const visibleNullify = ref(false)
    const tableData = ref([])
    const init = () => {
      loading.value = true
      getOutseaProformaPage(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
        emit('updateTotal', newSearchForm.value)
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const getStatisticsForm = () => {
      pageStatistics(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const created = () => {
      init()
      getStatisticsForm()
    }

    const refresh = () => {
      table.value.clear()
      created()
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      outseaProformaExport(props.tab, { ...newSearchForm.value }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的境外形式发票${props.label}报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    // 打开详情弹窗
    const detailForm = ref({})
    const openDetail = (settlementNo, platform) => {
      visibleDetails.value = true
      detailForm.value.settlementNo = settlementNo
      detailForm.value.platform = platform
    }

    // 查看
    const review = item => {
      const loadingRef = load()
      getPdfUrl(item.id).then(({ data }) => {
        loadingRef.close()
        if (data.status === 200) window.open(data.data)
      }).catch(error => {
        loadingRef.close()
        message.error(error?.response?.data?.message)
      })
    }

    // 待生成作废
    const infoNullify = item => {
      visibleNullify.value = true
      activeRow.value = item
    }

    // 确认
    const confirmId = ref(0)
    const confirm = item => {
      confirmId.value = item.id
      visibleConfirm.value = true
    }

    // 预览
    const previewFile = async item => {
      if (item.filePath.includes('http')) {
        if (!isImgFormat(item.fileName)) {
          window.open(item.filePath)
        } else {
          previewImage(item.filePath)
        }
      } else {
        vatAnnexPreview({ filePath: item.filePath }).then(({ data }) => {
          if (!isImgFormat(item.fileName)) {
            window.open(data.data)
          } else {
            previewImage(data.data)
          }
        })
      }
    }

    // 批量变更汇款状态
    const batchRemitForm = ref({})
    const batchChangeStatus = () => {
      batchRemitForm.value = {
        ids: selected.value.map(item => item.id),
        ...newSearchForm.value,
      }
      visibleBatchChangeRemit.value = true
    }

    const getSettlementNoList = arr => arr.flatMap(({ platform, settlementNoList }) => settlementNoList.map(settlementNo => ({ platform, settlementNo })))

    // 按钮
    const tableBtnList = reactive([
      {
        label: '查看',
        click: review,
        hidden: (() => props.label !== '待生成'),
        vAuth: ['offshore-proforma-invoice-view'],
      },
      {
        label: '确认',
        click: confirm,
        hidden: (() => props.label === '已生成'),
        vAuth: ['offshore-proforma-invoice-confirm'],
      },
      {
        label: '作废',
        click: infoNullify,
        hidden: (() => ['待生成', '已生成'].includes(props.label)),
        vAuth: ['offshore-proforma-invoice-cancel'],
      },
    ])

    const selectionChange = data => {
      selected.value = data
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.payoutPeriod = []
        created()
      }).catch(error => error)
    }

    const search = () => {
      page.page = 1
      created()
    }

    onMounted(() => {
      if (props.activeName === props.label) {
        created()
      }
    })

    return {
      newSearchConfig,
      formRef,
      page,
      tableData,
      reset,
      search,
      created,
      refresh,
      handleCurrentChange,
      handleSizeChange,
      statisticsForm,
      filterMap,
      searchForm,
      selectionChange,
      table,

      review,
      selected,
      remittanceStatusColorMap,
      paymentStatusColorMap,
      openDetail,
      detailForm,
      visibleDetails,
      getSettlementNoList,
      loading,
      textMap,
      tableBtnList,
      visibleConfirm,
      processNumbers,
      addSpace,
      exportExcel,
      confirmId,
      previewFile,
      visibleBatchChangeRemit,
      batchRemitForm,
      batchChangeStatus,
      visibleNullify,
      activeRow,

    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <SearchForm
          ref="formRef"
          :columns="newSearchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex align-center">
          <v-btn v-if="label === '已确认'" v-auth="['offshore-proforma-invoice-changestatus']" class="mr12" color="primary" @click="batchChangeStatus">
            批量变更汇款状态
          </v-btn>
          <v-btn v-auth="['offshore-proforma-invoice-export']" class="mr12" :color=" label === '已确认' ? '' : 'primary'" :outlined="label === '已确认' ? true : false" @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
          <div class="error--text fs14">
            {{ textMap[tab] }}
          </div>

          <div v-if="tab === 2 && selected.length" class="fs14">
            已选择 <span class="app-link">{{ selected.length }}</span> 条
          </div>
        </div>
        <div class="num">
          <span> 结算金额: <span class="amount">{{ processNumbers(statisticsForm.revenue,'$') }} </span></span>
        </div>
      </div>
    </template>

    <SuperTable
      ref="table"
      :columns="header"
      :data="tableData"
      :loading="loading"
      :checkbox-config="{reserve:true}"
      :row-config="{keyField:'id'}"
      @selection-change="selectionChange"
    >
      <template #payoutPeriod="{row}">
        <div class="domesticLinks">
          {{ row.payoutPeriod }}
        </div>
      </template>
      <template #settlementList="{row}">
        <xw-popover :list="row.settlementList" @openDetail="openDetail"></xw-popover>
      </template>

      <template #annexList="{row}">
        <div v-if="(row.annexList || []).length">
          <div v-for="item,index in row.annexList" :key="index" class="download-link" @click="previewFile(item)">
            {{ item.fileName }}
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #status="{row}">
        <xw-status-icon :color="remittanceStatusColorMap[row.status]" :text="filterMap('statusOptions',row.status)" />
      </template>

      <template #paymentStatus="{row}">
        <xw-status-icon :color="paymentStatusColorMap[row.paymentStatus]" :text="row.paymentStatus" />
      </template>

      <template #updatedAt="{row}">
        <div v-if="label === '已确认'">
          {{ row.paymentStatus === '已汇款' ? row.updatedAt : '-' }}
        </div>
        <div v-else>
          {{ row.updatedAt || '-' }}
        </div>
      </template>

      <template #actions="{row}">
        <ButtonList :list="tableBtnList" :item="row" />
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Details v-if="visibleDetails" :form="detailForm" @close="visibleDetails = false" />

    <ConfirmDialog v-if="visibleConfirm" :id="confirmId" @close="visibleConfirm = false" @refresh="created" />

    <BatchChangeRemitDialog v-if="visibleBatchChangeRemit" :form="batchRemitForm" platform="outsea" :tab="tab" @close="visibleBatchChangeRemit = false" @refresh="refresh" />

    <NullifyDialog v-if="visibleNullify" :active-row="activeRow" invoice-type="outsea" @close="visibleNullify = false" @refresh="created" />
  </AppView>
</template>
  <style scoped lang='scss'>
  .num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 10px;
  .amount{
      color: #FFA80F;
      padding: 0 10px;
  }
}
</style>
