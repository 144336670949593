<script>
import batchRegenerateBtn from '@/views/components/batchRegenerateBtn/index.vue'
import { MessageBox as MessageBoxElement } from 'element-ui'
import XwStatusIcon from '@/components/XwStatusIcon.vue'
import router from '@/router'
import { getExportTableColumns } from '@/auth/utils'
import { formatNumber, processNumbers, addSpace, addPercent } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, nextTick } from '@vue/composition-api'
import { downloadFile } from '@/@core/utils/form'
import { useMessage } from '@/hooks/useMessage'
import moment from 'moment'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { invoiceStatusColorMap, paymentStatusColorMap } from '@/@core/utils/colorMap'
import { adjustCurrencyMap } from '@/@core/utils/constant'
import ProgressLoading from '@/components/ProgressLoading/index.vue'
import AddAdjustTerm from '../../components/AddAdjustTerm/index.vue'
import Details from '../../components/StatementDetail/index.vue'
import NoSettlementDialog from './noSettlementDialog.vue'
import AuditDialog from './auditDialog.vue'
import mapEnum, { tabMap } from '../mapEnum'
import { getPage, exportPage, pageStatistics, reductionId, batchReduction, batchApproved, approved, batchRegenerate, byContractNum } from '../service'

export default {
  name: 'CommonTable',
  components: { SuperTable, Pagination, AppView, SearchForm, AuditDialog, NoSettlementDialog, Details, AddAdjustTerm, ProgressLoading, XwStatusIcon, batchRegenerateBtn },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '全部',
    },
    activeName: {
      type: String,
      default: '全部',
    },
  },
  setup(props, { emit }) {
    const table = ref()
    const batchRegenerateBtnRef = ref()
    const selected = ref([])
    const formRef = ref()
    const loading = ref(false)
    const { message, MessageBox } = useMessage()
    const searchForm = computed(() => props.formData).value

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const newSearchForm = computed(() => ({
      ...searchForm,
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
      month: undefined,
      settlementTab: props.tab || undefined,
      invoiceStatus: [tabMap.pushed].includes(props.label) ? searchForm.invoiceStatus.join(',') : undefined,
      paymentStatus: [tabMap.total, tabMap.pushed].includes(props.label) ? searchForm.paymentStatus : undefined,
    }))

    const exportTableColumns = getExportTableColumns(props.header, router.currentRoute.name)

    const searchConfig = computed(() => [
      {
        el: 'DateMonthRange', props: 'month', label: '到账月份', clearable: false, auth: true, spanDay: 365 * 3,
      },
      {
        el: 'VTextField', props: 'cpName', label: 'CP名称', auth: true,
      },
      {
        el: 'VTextField', props: 'channelName', label: '频道名称', auth: true,
      },
      {
        el: 'VTextField', props: 'channelId', label: '频道ID', auth: true,
      },
      {
        el: 'VTextField', props: 'subsetName', label: '子集名称', auth: true,
      },
      {
        el: 'VSelect', props: 'invoiceStatus', label: '发票状态', multiple: true, others: true, items: mapEnum.invoiceStatusOptions, itemText: 'label', itemValue: 'value', auth: [tabMap.pushed].includes(props.label),
      },
      {
        el: 'VTextField', props: 'settlementNo', label: '结算单编号', auth: true,
      },
      {
        el: 'VSelect', props: 'settlementType', label: '结算单类型', items: mapEnum.statementTypeOptions, itemText: 'label', itemValue: 'value', auth: true,
      },
      {
        el: 'VSelect', props: 'paymentStatus', label: '汇款状态', items: mapEnum.paymentStatusOptions, itemValue: 'value', itemText: 'label', auth: [tabMap.total, tabMap.pushed].includes(props.label),
      },
    ])

    const newSearchConfig = computed(() => searchConfig.value.filter(item => item.auth))

    const visibleAudit = ref(false)
    const visibleNoSettlement = ref(false)
    const visibleDetails = ref(false)
    const visibleAddAdjustTerm = ref(false)
    const visibleLoading = ref(false)
    const upPercentage = ref(0)
    const statisticsForm = ref({})
    const tableData = ref([])
    const init = () => {
      loading.value = true
      getPage({ ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
        emit('updateTotal', props.tab)
        selected.value = []
        table.value.clear()
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const getStatisticsForm = () => {
      pageStatistics({ ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const created = () => {
      init()
      const { customerCheckFirst, notSettled, ...args } = tabMap
      if (Object.values(args).includes(props.label)) {
        getStatisticsForm()
      }
    }

    // 导出
    const exportExcel = () => {
      message.info('导出中...请稍等')
      exportPage(1, { ...page, total: undefined, ...newSearchForm.value, headers: exportTableColumns }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的YT结算单${props.label}报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    // 按钮审核
    const auditForm = ref({})
    const audit = item => {
      auditForm.value.id = item.id
      auditForm.value.cpName = item.cpName || '暂无'
      auditForm.value.channelName = item.channelName || '暂无'
      auditForm.value.subsetName = item.subsetName
      auditForm.value.month = item.month || '暂无'
      visibleAudit.value = true
    }

    const detailForm = ref({
      platform: 'YT',
    })
    // 打开详情弹窗
    const openDetail = item => {
      visibleDetails.value = true
      detailForm.value.id = item.id
      detailForm.value.settlementNo = item.settlementNo
    }

    // 批量重新生成
    const batchAnewCreate = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      MessageBox({
        title: '批量重新生成',
        message: `已选择 ${selected.value.length} 条，重新生成前，请确认所需信息已更新，是否确认重新生成？`,
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        const ids = selected.value.map(item => item.id)
        visibleLoading.value = true
        batchRegenerate({ ids }, progressEvent => {
          upPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        })
          .then(({ data }) => {
            if (data.status === 200) message.success('操作成功')
            init()
          })
          .catch(error => {
            MessageBoxElement.alert(`
              <p style="font-size:15px;"> 失败原因：${error.response.data.message}</p>
              <p style="font-size:15px;margin-top:10px;">重新生成后，不满足结算条件，结算单将删除，是否确认重新生成？</p>`, '批量重新生成', {
              showCancelButton: true,
              dangerouslyUseHTMLString: true,
              cancelButtonText: addSpace('取消'),
              confirmButtonText: addSpace('确认'),
            }).then(() => {
              batchRegenerate({ ids, flag: 1 }).then(({ data }) => {
                if (data.status === 200) message.success('操作成功')
                init()
              }).catch(err => message.error(err.response.data.message))
            })
          })
          .finally(() => {
            visibleLoading.value = false
          })
      }).catch(error => error)
    }

    // 合约编号生成
    const regenerateByContractNum = formData => {
      const ids = selected.value.map(item => item.id)
      byContractNum({ ids, ...formData }).then(() => {
        message.success('操作成功')
        batchRegenerateBtnRef.value.visibleContractNo = false
        created()
      }).catch(error => message.error(error.response.data.message))
    }

    // 批量审核通过
    const batchExamineSuccess = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      MessageBox({
        title: '批量审核通过',
        message: `已选择 ${selected.value.length} 条，是否全部审核通过？`,
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        const ids = selected.value.map(item => item.id)
        batchApproved({ ids }).then(({ data }) => {
          if (data.status === 200) {
            message.success('操作成功')
            init()
          }
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    // 按钮审核通过
    const examineSuccess = id => {
      MessageBox({
        title: '审核通过',
        message: '审核通过后，结算单将显示在小程序和网页端，是否确认审核通过？',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        approved(id).then(({ data }) => {
          if (data.status === 200) message.success('审核成功')
          init()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    // 暂不结算
    const noSettledForm = ref({})
    const noSettlement = id => {
      noSettledForm.value.id = id
      noSettledForm.value.isBatch = false
      visibleNoSettlement.value = true
    }

    // 批量暂不结算
    const batchNoSettlement = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      const ids = selected.value.map(item => item.id)
      noSettledForm.value.ids = ids
      noSettledForm.value.isBatch = true
      visibleNoSettlement.value = true
    }

    // 还原
    const restore = id => {
      MessageBox({
        title: '还原',
        message: '结算单将还原到暂不结算之前的tab下对应月份的列表中，是否确认还原？',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        reductionId(id).then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          init()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    // 批量还原
    const batchRestore = () => {
      if (!selected.value.length) return message.warning('请至少选择一条数据')
      MessageBox({
        title: '批量还原',
        message: `已选择 ${selected.value.length} 条，结算单将还原到暂不结算之前的tab下对应月份的列表中，是否确认还原？`,
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        const ids = selected.value.map(item => item.id)
        batchReduction({ ids }).then(({ data }) => {
          console.log(data)
          if (data.status === 200) message.success('操作成功')
          init()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    // 添加调整项
    const adjustmentForm = reactive({
      id: 0,
      title: undefined,
    })
    const addAdjustTerm = item => {
      adjustmentForm.id = item.id
      adjustmentForm.channelName = item.channelName
      adjustmentForm.subsetName = item.subsetName
      visibleAddAdjustTerm.value = true
    }

    const checkBoxChange = ({ records }) => {
      selected.value = records
    }

    const selectAllEvent = ({ records }) => {
      selected.value = records
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.month = [moment().subtract(3, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')]
        created()
      }).catch(error => error)
    }

    const search = () => {
      page.page = 1
      created()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return (data && data.label) || '-'
    }

    onMounted(() => {
      if (props.activeName === props.label) {
        created()
      }
    })

    return {
      newSearchConfig,
      formRef,
      page,
      tableData,
      reset,
      search,
      init,
      created,
      handleCurrentChange,
      handleSizeChange,
      statisticsForm,
      filterMap,
      exportExcel,
      searchForm,
      checkBoxChange,
      selectAllEvent,
      table,

      visibleAudit,
      visibleNoSettlement,
      visibleDetails,
      audit,
      batchAnewCreate,
      batchExamineSuccess,
      examineSuccess,
      noSettlement,
      batchNoSettlement,
      restore,
      batchRestore,
      openDetail,
      selected,

      auditForm,
      detailForm,
      noSettledForm,
      invoiceStatusColorMap,
      paymentStatusColorMap,
      adjustCurrencyMap,
      loading,
      addAdjustTerm,
      visibleAddAdjustTerm,
      adjustmentForm,
      processNumbers,
      addSpace,
      upPercentage,
      visibleLoading,
      router,
      addPercent,
      regenerateByContractNum,
      batchRegenerateBtnRef,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <SearchForm
          ref="formRef"
          :columns="newSearchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex align-center">
          <v-btn v-if="label === '暂不结算'" v-auth="['ytStatement-restore']" color="primary" class="app-link" @click="batchRestore">
            批量还原
          </v-btn>
          <v-btn v-if="['待财务审核'].includes(label)" v-auth="['ytStatement-finance-audit']" color="primary" class="app-link" @click="batchExamineSuccess">
            批量审核通过
          </v-btn>
          <v-btn v-if="['待客服审核','待客服二审'].includes(label)" v-auth="['ytStatement-customer-audit']" color="primary" class="app-link" @click="batchExamineSuccess">
            批量审核通过
          </v-btn>
          <v-btn v-if="['待客服审核','待客服二审'].includes(label)" v-auth="['ytStatement-no-settlement']" outlined class="app-link" @click="batchNoSettlement">
            批量暂不结算
          </v-btn>
          <v-btn v-if="['待财务审核'].includes(label)" v-auth="['ytStatement-no-settlement-financeCheck']" outlined class="app-link" @click="batchNoSettlement">
            批量暂不结算
          </v-btn>

          <batchRegenerateBtn v-if="!['全部','已推送'].includes(label)" ref="batchRegenerateBtnRef" v-auth="['ytStatement-regenerate']" :outlined="true" :selected="selected" @batchRegenerate="batchAnewCreate" @regenerateByContractNum="regenerateByContractNum" />

          <v-btn v-if="['全部','已推送'].includes(label)" v-auth="['ytStatement-export']" color="primary" class="app-link" @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
          <v-btn v-else v-auth="['ytStatement-export']" outlined class="app-link" @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
        </div>
        <div v-if="['全部', '待财务审核', '待客服二审', '已推送'].includes(label)" class="num">
          <span v-auth="['ytStatement-total-revenue']"> 频道收益($): <span class="amount"> {{ processNumbers(statisticsForm.revenue) }} </span></span>
          <span v-auth="['ytStatement-total-federalTaxAmount']"> 联邦税($): <span class="amount"> {{ processNumbers(statisticsForm.federalTaxAmount) }} </span></span>
          <span v-auth="['ytStatement-total-serviceChargeDollar']"> 手续费($): <span class="amount"> {{ processNumbers(statisticsForm.serviceChargeDollar) }} </span></span>
          <span v-if="label === '全部'" v-auth="['ytStatement-total-actualIncomeRmb']"> 实发($): <span class="amount"> {{ processNumbers(statisticsForm.actualIncomeDollar) }} </span></span>
          <span v-else v-auth="['ytStatement-total-actualIncomeRmb']"> 实发(￥): <span class="amount"> {{ processNumbers(statisticsForm.actualIncomeRmb) }} </span></span>
        </div>
      </div>
    </template>

    <SuperTable
      ref="table"
      :columns="header"
      :data="tableData"
      :loading="loading"
      :router="router.currentRoute.name"
      @checkBoxChange="checkBoxChange"
      @selectAllEvent="selectAllEvent"
    >
      <template #channelName="{row}">
        <div>{{ row.channelName }}</div>
        <div>{{ row.channelId }}</div>
      </template>
      <template #proportion="{row}">
        {{ addPercent(row.proportion) }}
      </template>
      <template #federalTax="{row}">
        {{ addPercent(row.federalTax ) }}
      </template>
      <template #serviceCharge="{row}">
        {{ addPercent(row.serviceCharge) }}
      </template>
      <template #adjustAmount="{row}">
        {{ row.adjustAmount ? row.adjustAmount === '0' ? '-' : (adjustCurrencyMap[row.adjustCurrency] + row.adjustAmount) : '-' }}
      </template>
      <template #adjustCurrency="{row}">
        {{ (row.adjustAmount && row.adjustAmount !== '0') ? row.adjustCurrency : '-' }}
      </template>
      <template #settlementNo="{row}">
        <div class="app-link" @click="openDetail(row)">
          {{ row.settlementNo || '-' }}
        </div>
      </template>
      <template #checkStatus="{row}">
        <xw-status-icon :color="row.checkStatus === 4 ? '#00B09B' : '#2CA0F8'" :text="filterMap('checkStatusOptions',row.checkStatus)" />
      </template>

      <template #invoiceStatus="{row}">
        <xw-status-icon :color="invoiceStatusColorMap[row.invoiceStatus]" :text="filterMap('invoiceStatusOptions',row.invoiceStatus)" />
      </template>

      <template #paymentStatus="{row}">
        <xw-status-icon :color="paymentStatusColorMap[row.paymentStatus]" :text="row.paymentStatus" />
      </template>

      <template #actions="{row}">
        <span v-if="label=== '待客服二审'" v-auth="['ytStatement-customer-audit']" class="app-link" @click="examineSuccess(row.id)">审核通过</span>
        <span v-if="label=== '暂不结算'" v-auth="['ytStatement-restore']" class="app-link" @click="restore(row.id)">还原</span>
        <span v-if="['全部'].includes(label) " v-auth="['ytStatement-customer-audit','ytStatement-finance-audit']" class="app-link" @click="audit(row)">审核</span>
        <span v-if="['待客服审核'].includes(label) " v-auth="['ytStatement-customer-audit']" class="app-link" @click="audit(row)">审核</span>
        <span v-if="['待财务审核'].includes(label) " v-auth="['ytStatement-finance-audit']" class="app-link" @click="audit(row)">审核</span>
        <span v-if="['待财务审核'].includes(label)" v-auth="['ytStatement-finance-add-adjustment']" class="app-link" @click="addAdjustTerm(row)">添加调整项</span>
        <span v-if="['待客服二审'].includes(label)" v-auth="['ytStatement-add-adjustment']" class="app-link" @click="addAdjustTerm(row)">添加调整项</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <AuditDialog v-if="visibleAudit" :form="auditForm" @close="visibleAudit = false" @refresh="created" />

    <NoSettlementDialog v-if="visibleNoSettlement" :form="noSettledForm" @close="visibleNoSettlement = false" @refresh="created" />

    <Details v-if="visibleDetails" :form="detailForm" @close="visibleDetails = false" />

    <AddAdjustTerm v-if="visibleAddAdjustTerm" :form="adjustmentForm" @close="visibleAddAdjustTerm = false" @refresh="created" />

    <ProgressLoading v-if="visibleLoading" :progress="upPercentage" title="正在重新生成" @close="visibleLoading = false" />
  </AppView>
</template>
  <style scoped lang='scss'>
  .num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 10px;
  .amount{
      color: #FFA80F;
      padding: 0 20px 0 10px;
  }
}

::v-deep .vl-message-box__wrapper__body__content{
  white-space: pre-line !important;
}

::v-deep .vl-message-box__wrapper__body{
  padding: 0;
}
  </style>
